<template>
  <div class="view-model">
 
    <div id="container" class="conta">
      <!--进度条-->
      <div id="progress" class="progress">
        <div class="mask"></div>
        <div class="loading">
          <div class="trends"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Loading } from "element-ui";

export default {
  name: "stldemo",
  data() {
    return {
      loadingInstance: "",
      camera: "",
      scene: "",
      renderer: "",
      mesh: "",
      isMouseDown: false,
    };
  },
  mounted() {
    this.getType();
    // window.onresize = () => {
    //     this.onWindowResize();
    // };
  },
  methods: {
    toLink(val){           
                this.$router.push(val);
            },
    onMouseDown() {
      this.isMouseDown = true;
    },
    onMouseUp() {
      this.isMouseDown = false;
    },
    getType() {
      this.loadingInstance = Loading.service({
        lock: true,
        text: "loading",
        spinner: "el-icon-loading",
      });

      this.getScene();
      this.initControls();
      this.initStlModel();
      this.animate();
    },
    getScene() {
      let container = document.getElementById("container");
      container.addEventListener("pointerdown", this.onMouseDown, false);
      container.addEventListener("pointerup", this.onMouseUp, false);
      this.camera = new THREE.PerspectiveCamera(
        2,
        document.getElementById("container").clientWidth /
          document.getElementById("container").clientHeight,
        0.25,
        700
      );

      this.camera.position.set(0, 200, 300);
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      this.scene = new THREE.Scene();

      var spotLight = new THREE.SpotLight(0xffffff, 1, 100);
      spotLight.position.set(0, 4, 0);
      this.scene.add(spotLight);           
      this.scene.add(this.camera);

      // 显示坐标系
      // var axes = new THREE.AxesHelper(100);
      // this.scene.add(axes)
      //背景颜色
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        logarithmicDepthBuffer: true,
      });
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      // this.renderer.setClearAlpha(0.3);
      //设置分辨率
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(this.renderer.domElement);
      /*
        添加光源
      */
      //点光源
      var point = new THREE.PointLight(0xffffff);
      point.position.set(1000, 200, 300); //点光源位置
      this.scene.add(point); //点光源添加到场景中
      //环境光
      var ambient = new THREE.AmbientLight(0x999999);
      this.scene.add(ambient);

      
      //模型宽高

    },
    //加载gltf文件
    initStlModel() {

      // 加载过程回调函数-可以获得加载进度
      THREE.DefaultLoadingManager.onProgress = function (
        itemPath,
        itemIndex,
        totalItems
      ) {
        var percentComplete = (itemIndex / totalItems) * 100;
        console.log(itemPath, itemIndex, totalItems);
        console.log(Math.round(percentComplete, 2) + "% downloaded");
        play(Math.round(percentComplete, 2));
      };
      // 这是一个进度条函数进度条函数
      function play(a) {
        document.getElementsByClassName("trends")[0].style.width = a + "%";
        if (a < 100) {
          document.getElementById("progress").style.display = "block";
        } else {
          document.getElementById("progress").style.display = "none";
        }
      }
      //     //添加gltf
      var self = this;
      var loader = new GLTFLoader();
      loader.load("DamagedHelmet.gltf", function (gltf) {
        self.mesh = gltf.scene;
        console.log(self.mesh);
        self.scene.add(self.mesh); // 将模型引入three
          
        self.animate();
        self.render();
 var box=new THREE.BoxGeometry(3,3,3)
var mail=new THREE.MeshBasicMaterial({color:0xff6600})
var mesh=new THREE.Mesh(box,mail)
//mesh:模型
var box = new THREE.Box3().setFromObject( mesh );
var size = box.size();

      });
    
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    animate() {
      //  console.log(this.isMouseDown)
      if (!this.isMouseDown) {
    
        //  console.log(this.mesh.rotation.y )
        this.mesh.rotation.y -= 0.005;
      }
      this.render();
      requestAnimationFrame(this.animate);
    },

    initControls() {
      var controls = new OrbitControls(this.camera, this.renderer.domElement);

      //解决选中时的边框问题
      this.renderer.domElement.removeAttribute("tabindex");
      // 如果使用animate方法时，将此函数删除
      // controls.addEventListener("change", this.render);

      // 使动画循环使用时阻尼或自转 意思是否有惯性
      controls.enableDamping = true;
      //动态阻尼系数 就是鼠标拖拽旋转灵敏度
      //controls.dampingFactor = 0.25;
      //是否可以缩放
      controls.enableZoom = true;
      //是否自动旋转
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.5;
      //设置相机距离原点的最远距离
      controls.minDistance = 1;
      //设置相机距离原点的最远距离
      // controls.maxDistance = 200;
      //是否开启右键拖拽
      controls.enablePan = false;
      controls.target.set(0, 0, -0.2);
    },
  },
};
</script>

<style scoped>
.view-model {
  height: 100%;
  width: 100%;
  position: relative;
  /* background: #a0a2a6; */
}
.conta {
  height: 100%;
  width: 100%;
}
/* 进度条 */
.progress {
  position: fixed;
  width: 100%;
  height: 100%;
}
.progress .mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
}
.progress .loading {
  width: 30em;
  height: 1em;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 1em;
}
.progress .trends {
  width: 0;
  height: 100%;
  background: rgb(134, 130, 130);
  box-shadow: 1px 1px 10px rgb(100, 98, 98);
  border-radius: 1em;
}
</style>